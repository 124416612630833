// alioss.js
import OSS from 'ali-oss'
// const OSS = require('ali-oss');

const client = new OSS({
  region: 'oss-cn-hangzhou',
  accessKeyId: 'LTAI5tH2Ht6UnkjNr4C61SNv',
  accessKeySecret: 'uCyPm4qPLBUHhKrw3hYkSjt5QDGFL7',
  bucket: 'chaolu'
});

export default client;
